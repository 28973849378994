import logo from '../assets/cdm_logo.png'



const Loading = () => {

    return (
     
        <img className="App-logo" src={logo} alt="Bestes Logo der Welt!" />
 
    )
}

export default Loading;